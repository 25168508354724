<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-8 c g">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-calendar"></i>
            {{ e("freedays") }}
          </h4>
        </div>
        <div class="card-body" v-if="!bet">
          <div class="col-12 g" v-if="to">
            <div class="alert alert-success g text-center">
              <h1>{{ e('will-add-to') }} {{ to }}</h1>
            </div>
          </div>
          <div class="form-group">
            <label for="">{{ e("select-input-date") }}</label>
            <input
              v-if="!hijri"
              type="date"
              class="form-control"
              v-model="date"
            />
            <date-picker
              v-if="hijri"
              v-model="date"
              :locale="'ar-sa'"
              :calendar="'hijri'"
            ></date-picker>
            <span v-if="hijri">
              <h2 class="text-danger g text-cneter" v-if="date">
                {{ date }} هـ
              </h2>
            </span>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-relief-success" @click="add()">
              <i class="fa fa-plus"></i>
              {{ e("add") }}
            </button>
            <br /><br />
            <button @click="bet = true" class="btn btn-link">
              <u>
                {{ e("want-to-add-between") }}
              </u>
            </button>
          </div>
        </div>
        <div class="card-body" v-if="bet">
          <div class="form-group">
            <label for="">{{ e("date-from") }}</label>
            <input
              v-if="!hijri"
              type="date"
              class="form-control"
              v-model="start"
            />
            <date-picker
              v-if="hijri"
              v-model="start"
              :locale="'ar-sa'"
              :calendar="'hijri'"
            ></date-picker>
            <span v-if="hijri">
              <h2 class="text-danger g text-cneter" v-if="start">
                {{ start }} هـ
              </h2>
            </span>
          </div>
          <div class="form-group">
            <label for="">{{ e("date-to") }}</label>
            <input
              v-if="!hijri"
              type="date"
              class="form-control"
              v-model="end"
            />
            <date-picker
              v-if="hijri"
              v-model="end"
              :locale="'ar-sa'"
              :calendar="'hijri'"
            ></date-picker>
            <span v-if="hijri">
              <h2 class="text-danger g text-cneter" v-if="end">{{ end }} هـ</h2>
            </span>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-relief-success" @click="add2()">
              <i class="fa fa-plus"></i>
              {{ e("add") }}
            </button>
          </div>
        </div>
        <div class="card-footer">
          <div v-if="days.length == 0">
            <div class="alert alert-danger text-center g">
              {{ e("nothing-added-yet") }}
            </div>
          </div>
          <div class="col-12 table-responsive" v-if="days.length">
            <table class="table table-hover">
              <thead>
                <th>{{ e("date") }}</th>
                <th>{{ e("to") }}</th>
                <th>{{ e("delete") }}</th>
              </thead>
              <tbody>
                <tr v-for="day in days" :key="day._id">
                  <td>
                    {{ cdate(day.date) }}
                  </td>
                  <td>
                    {{ day.to == "all" ? e("all") : day.to ? day.to : "--" }}
                  </td>
                  <td>
                    <button
                      class="btn text-danger"
                      v-if="abletodelete(day.date)"
                      @click="deleteDay(day._id)"
                    >
                      <i class="fa fa-trash"></i>
                      {{ e("delete") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueDatetimeJs from "vue-datetime-js";
var moment = require("moment-timezone");
var moment2 = require("moment-hijri");
import { BLink, BOverlay, BFormCheckbox } from "bootstrap-vue";
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
    datePicker: VueDatetimeJs,
  },
  data() {
    return {
      loading: false,
      days: [],
      date: null,
      user: JSON.parse(localStorage.getItem("user")),
      hijri: false,
      bet: false,
      start: null,
      end: null,
      to: window.location.href.split("number=").length
        ? window.location.href.split("number=")[1]
        : false,
    };
  },
  created() {
    if (cdate("2022-02-02").includes("ه")) {
      this.hijri = true;
    }
    this.getDays();
  },
  methods: {
    e(d) {
      return e(d);
    },
    add() {
      var date = this.date,
        g = this;
      if (cdate("2222-22-22").includes("ه")) {
        date = a2e(date);
        date = moment2(date, "iYYYY/iM/iD").format("YYYY-MM-DD");
      }
      $.post(api + "/general/freedays/add", {
        jwt: this.user.jwt,
        date: date,
        to: window.location.href.split("number=").length
          ? window.location.href.split("number=")[1]
          : null,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("done"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.getDays();
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          alert(e("error") + ": " + e);
        });
    },
    add2() {
      var start = this.start,
        end = this.end,
        g = this;
      if (cdate("2222-22-22").includes("ه")) {
        start = a2e(start);
        start = moment2(start, "iYYYY/iM/iD").format("YYYY-MM-DD");
        end = a2e(end);
        end = moment2(end, "iYYYY/iM/iD").format("YYYY-MM-DD");
      }
      $.post(api + "/general/freedays/add", {
        jwt: this.user.jwt,
        start: start,
        end: end,
        to: window.location.href.split("number=").length
          ? window.location.href.split("number=")[1]
          : null,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("done"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.getDays();
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          alert(e("error") + ": " + e);
        });
    },
    getDays() {
      var g = this;
      $.post(api + "/general/freedays", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 100) {
            g.days = r.response;
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          alert(e("error") + ": " + e);
        });
    },
    abletodelete(date) {
      if (
        moment(date) >=
        moment(new Date().toISOString().toString().split("T")[0])
      ) {
        return true;
      } else {
        return false;
      }
    },
    cdate(g) {
      return cdate(g);
    },
    deleteDay(id) {
      if (confirm(e("confirm") + "\n" + e("maybe-affect-reports"))) {
        var g = this;
        $.post(api + "/general/freedays/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (r) {
            r = JSON.parse(r);
            if (r.status == 100) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("done"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              g.getDays();
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e(r.response),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function (e) {
            alert(e("error") + ": " + e);
          });
      }
    },
  },
};
</script>